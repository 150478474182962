.triangle-br,
.triangle-tl {
    position:absolute;
    width:100%;
    height:50vw;
    background-size:100% 100%;
    pointer-events: none;
}

.triangle-br {
    //transform: translateY(-100%); //Safari Bug https://gitlab.com/pixmill/hug-und-dorfmueller/-/issues/9
    transform: translateY(calc(-100% + 1px));
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none'><path d='M 0 100 L 100 0 L 100 100 Z' fill='%23FF526C'></path></svg>");
}

.triangle-tl {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none'><path d='M 0 0 L 100 0 L 0 100 Z' fill='%23FF526C'></path></svg>");
}
