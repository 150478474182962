
@font-face {
    font-family: 'Franca';
    src: url('/assets/app/webfonts/franca-book-webfont.woff2') format('woff2'),
         url('/assets/app/webfonts/franca-book-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Franca';
    src: url('/assets/app/webfonts/franca-bookitalic-webfont.woff2') format('woff2'),
         url('/assets/app/webfonts/franca-bookitalic-webfont.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Franca';
    src: url('/assets/app/webfonts/franca-medium-webfont.woff2') format('woff2'),
         url('/assets/app/webfonts/franca-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Franca';
    src: url('/assets/app/webfonts/franca-mediumitalic-webfont.woff2') format('woff2'),
         url('/assets/app/webfonts/franca-mediumitalic-webfont.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Franca';
    src: url('/assets/app/webfonts/franca-semibold-webfont.woff2') format('woff2'),
         url('/assets/app/webfonts/franca-semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Franca';
    src: url('/assets/app/webfonts/franca-semibolditalic-webfont.woff2') format('woff2'),
         url('/assets/app/webfonts/franca-semibolditalic-webfont.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Franca';
    src: url('/assets/app/webfonts/franca-bold-webfont.woff2') format('woff2'),
         url('/assets/app/webfonts/franca-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Franca';
    src: url('/assets/app/webfonts/franca-bolditalic-webfont.woff2') format('woff2'),
         url('/assets/app/webfonts/franca-bolditalic-webfont.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}
