@mixin typo-quote() {
    .quote {
        @include typo-fs34();
        line-height: 1.175;
        font-weight: 700;
    }
    .author {
        margin-top: 1em;
        font-weight: 700;
    }
    .funktion {
        font-weight: 500;
    }
    .author,
    .funktion {
        @include font-size-pt(14, 22);
    }
}

.bl-quote {
    color:$whitetext;
    @include typo-quote();
}
