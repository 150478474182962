.wconstrain {
    margin-left: auto;
    margin-right: auto;

    --maxw: calc(100vw - 2*var(--xlmargin));

    //width: min(rem-calc(1090), var(--maxw)); //default (w_m)
    --w_m: calc(100vw - 4*var(--xlmargin));
    width: min(var(--w_m), var(--maxw)); //default (w_m)

    &.w_full {
        width:100%;
    }
    &.w_xl {
        width: var(--maxw);
    }
    &.w_s {
        width: min(rem-calc(750), var(--maxw));
    }

    @include breakpoint(medium down) {
        --w_m: var(--maxw);
    }

    /*
    @media (min-width:1300px) {
        &.w_l {
            width: calc(100% - 24rem);
        }
        &.w_m {
            //https://stackoverflow.com/questions/62280339/css-min-function-to-sass
            --temp0: calc(100% - 24rem);
            width: min(70rem, var(--temp0));
        }
    }
    */
}
