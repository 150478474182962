/*
.h-fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: $light-gray;
  z-index: 10;
  transition: all 0.2s ease-in-out;
  &.opaque {
    @include shadow(0 2px 7px 0 rgba($black, 0.1));
  }
}
*/

header {
  padding-top:rem-calc(25);
  @include breakpoint(small down) {
    padding-top:rem-calc(20);
  }

  z-index: 20;

  .ctrtopbar {
    display:flex;
    justify-content: space-between;
    align-items: center;
  }

  .logotext {
    width:rem-calc(210);
    @include breakpoint(small down) {
      width:rem-calc(140);
    }
    img {
      width:100%;
    }
  }
  .logoimg {
    width:rem-calc(55);
    @include breakpoint(small down) {
      width:rem-calc(30);
    }
    transform:translateY(rem-calc(-3));
    img {
      width:100%;
    }
  }
}

header.home {
  position:fixed;
  left:0;
  right:0;
  top:0;
}

header.contentpage {
  padding-bottom:rem-calc(25);
  @include breakpoint(small down) {
    padding-bottom:rem-calc(20);
  }
  background-color: $contentpagebg;

  position:sticky;
  top:0;
}