.bl-teamlist {
    .items {
        display:grid;
        grid-template-columns: repeat(3, 1fr);
        gap:rem-calc(52) var(--gap1);
    }
    .photo  {
        display: block;
        width: 100%;
        margin-bottom:0.5em;
    }

    color:$whitetext;

    h2, .funktion {
        @include typo-fs28();
        letter-spacing: 0.02em;
        font-weight: 400;
    }
    h2 {
        margin:0;
        font-weight:600;
    }

    .buttons {
        margin-top:0.75em;
        display:flex;
        flex-direction: column;
        align-items: flex-start;
        gap:rem-calc(10);

        .button {
            @include font-size-pt(11, 14);
            letter-spacing: 0.025em;
            font-weight: 600;
        }
    }
    .button {
        padding:0 0.75em;
    }
}


@include breakpoint(medium down) {
    .bl-teamlist {
        .items {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
@include breakpoint(small down) {
    .bl-teamlist {
        .items {
            grid-template-columns: repeat(2, 1fr);
            gap: rem-calc(25 18);
        }

        h2, .funktion {
            font-size:rem-calc(16);
            line-height: 1.143;
        }
    }
}