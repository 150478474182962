.bl-gallery {
    .items {
        display:grid;
        grid-template-columns: repeat(2, 1fr);
        gap:var(--gap1);
    }

    img {
        display:block;
        width:100%;
    }
}