body {
    --mpbaseunit: 1.5rem;
    @include breakpoint (small down) {
        --mpbaseunit: 0.75rem;
    }
}
@for $i from 1 through 5 {
    .pt_#{$i} {
        padding-top:calc(#{$i} * var(--mpbaseunit));
    }
    .pb_#{$i} {
        padding-bottom:calc(#{$i} * var(--mpbaseunit));
    }

    .mt_#{$i} {
        margin-top:calc(#{$i} * var(--mpbaseunit));
    }
    .mb_#{$i} {
        margin-bottom:calc(#{$i} * var(--mpbaseunit));
    }
}

section {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    &.topline {
        border-top:3px solid #FFFFFF;
    }
}

section.bg-red-diag {
    position: relative;
}
section.bg-red-diag::before {
    position: absolute;
    content:"";
    z-index:-1;
    left:0;
    right:0;
    top:-25vw;
    bottom:-25vw;
    background-color: $redbg;
    transform: skewY(-26.565deg);
}

section.bg-team::before {
    top:0;
    bottom:7vw;    
}

section.bg-logolist::before {
    top:-10vw;
    bottom:-5vw;    
    @include breakpoint (small down) {
        bottom:-10vw;    
    }
}
