.off-canvas {
  display:flex;
  flex-direction: column;
  justify-content: space-between;

  //z-index: 100 !important;
  max-width: 100%;
  padding:var(--footerbottommargin) rem-calc(20);
  
  @include typo-fs68();
  font-weight: 400;
  color:#878787;

  .menu a {
    display: block;
    padding:0;
    &:hover {
      color:$whitetext;
    }
    line-height:1.087;
  }

  .toptitle {
    color:$whitetext;
    margin-bottom: 1em;
  }

  .socialbar {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    color:$whitetext;

    .buttons {
      display: flex;
      gap:var(--gap1);

      .button, .icon {
        height:3.5rem;
        @include breakpoint (medium down) {
          height:2.5rem;
        }
      }

      .icon {
        display:block;
      }

      .button {
        color:$whitetext;
        background-color: #303030;
        &:hover {
          //inverse
          color:#303030;
          background-color: $whitetext;
        }
      }
    }
    .socialicons {
      display:flex;
      gap:rem-calc(16);
    }
    .txtimpds {
      @include font-size-pt(16, 22);
      font-weight: 600;
      line-height: 1.375;
      color:#878787;
      a:hover {
        color:$whitetext;
      }
    }
  }
}

@include breakpoint (small down) {
  .off-canvas {
    .socialbar {
      display:block;

      .txtimpds {
        margin-bottom:rem-calc(28);
      }
      .txtfollow {
        margin-bottom:rem-calc(28);
      }
    }
    .buttons {
      justify-content: space-between;
    }
  }
}