.menubutton,
.button {
  border-radius:rem-calc(30);
  box-shadow:0 -1px 3px rgba(255, 255, 255, .1), 0 3px 6px -3px rgba(0, 0, 0, .1);
  background-color: #ff637b;
  padding:rem-calc(0 45);
  display:inline-flex;
  align-items: center;
  cursor: pointer;

  @include font-size-pt(16, 22);
  font-weight: 700;
  letter-spacing: 0.03em;
  color:$whitetext;

  &:hover {
    color: $redbg;
    background-color: $whitebg;
  }

  @include breakpoint(medium down) {
    padding-left:rem-calc(24);
    padding-right:rem-calc(24);
  }
}

.button {
  padding-top:0.5em;
  padding-bottom:0.5em;
}