.bl-projdetail-intro {
    .lead {
        @include typo-redtext;
        @include font-size-pt(11, 16);
        line-height: 1.2;
        margin-bottom: 2em;
    }
}

@include breakpoint (small down) {
    .bl-projdetail-intro {
        .text {
            font-size:rem-calc(16);
            line-height: 1.375;
            font-weight: 400;
        }
    }
}
