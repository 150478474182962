.bl-pageendhd {
    .pecontent {
        position: relative;
        background-color:$redbg;
        height:100lvh;
        height:100vh;
        background-image:url('/assets/app/images/herobg.svg');
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
    }

    footer {
        position: absolute;
        left:0;
        right:0;
        bottom:var(--footerbottommargin);
    }
}