.bl-logolist {
    .items {
        display:grid;
        grid-template-columns: repeat(4, 1fr);
        align-items: center;
        gap:rem-calc(200 100);
        transform: skewY(-26.565deg);
        .item {
            transform: skewY(26.565deg);
            .logoimg {
                display: block;
                margin-left:auto;
                margin-right: auto;
            }
        }
    }
}

@include breakpoint(medium down) {
    .bl-logolist {
        .items {
            grid-template-columns: repeat(3, 1fr);
            gap:rem-calc(100 75);

            margin-left: auto;
            margin-right: auto;
            width: 90%;
        }
    }
}
@include breakpoint(small down) {
    .bl-logolist {
        .items {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}