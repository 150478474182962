.bl-quotewithimage {
    display:grid;
    grid-template-columns: 2fr 1fr;
    gap:var(--gap1);
    align-items: center;

    .colimage {
        img {
            display:block;
            width:100%;
        }
    }
    .coltext {
        @include typo-redtext;
        @include typo-quote;
    }

    &.imgpos-right {
        grid-template-columns: 1fr 2fr;
        .colimage {
            order:1;
        }
    }
}

@include breakpoint(medium down) {
    .bl-quotewithimage {
        display:flex;
        flex-direction: column;

        .coltext {
            order:1;
        }
    }
}
