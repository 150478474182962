.bl-herohd {
    .herocontent {
        background-color:$redbg;
        height:100lvh;
        height:100vh;
        background-image:url('/assets/app/images/herobg.svg');
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
    }
}