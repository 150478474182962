$redtext: #FF526C;
$blacktext: #0F0F0F;
$whitetext: #F5F5F5;

$redbg: #FF526C;
$whitebg: #F5F5F5;
$contentpagebg: #F5F5F5;

body {
    --xlmargin: 4.5rem;
    @include breakpoint (medium down) {
        --xlmargin: 1rem;
    }
    --gap1: 2rem;
    @include breakpoint(small down) {
        --gap1: 1rem;
    }
    --footerbottommargin: 2rem;
    @include breakpoint (small down) {
        --footerbottommargin: 1.125rem;
    }

}

