footer .socialicons .icon,
.menubutton {
  height:3.5rem;
  @include breakpoint (medium down) {
    height:2.5rem;
  }
}

footer {
  color:white;
  @include font-size-pt(16, 15);
  line-height: 1.2;
  font-weight:600;

  .ctrcontent {
    display:flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .ctraddress {
    display:flex;
    gap:2em;
  }

  .ctrbuttons {
    display:flex;
    gap:2em;
  }
  .socialicons {
    display: flex;
    gap:rem-calc(16);

    .icon {
      display:block;
    }
  }

  @include breakpoint (medium down) {
    .ctrcontent {
      display:block;
    }

    .ctraddress {
      display:none;
    }

    .ctrbuttons {
      justify-content: space-between;
    }
  }
}

#scrolldownarrow {
  position:fixed;
  left:50%;
  bottom:var(--footerbottommargin);
  transform:translateX(-50%);
  pointer-events: none;
  //transition: opacity 0.75s;
  //animation: 1s ease-out 0.5s 1 normal backwards running scrolldownarrow_appear;

  height:rem-calc(48);
  color: $whitetext;

  @include breakpoint(medium down) {
    height:rem-calc(36);
  }
}
.scrollygt0 #scrolldownarrow {
  //animation:none;
  opacity:0 !important;
}
/*
@keyframes scrolldownarrow_appear {
  from {
    opacity:0;
    transform: translateX(-50%) translateY(-30px);
  }
}
*/


.ct-home footer {
  position:fixed;
}
.ct-contentpage footer .menubutton {
  visibility: hidden;
}

.floatingmenu {
  position:fixed;
  left:0;
  right:0;
  bottom:var(--footerbottommargin);
  text-align: right;
  
  pointer-events: none;
  .menubutton {
    pointer-events: auto;
  }
}