

//global font-size (small, medium, large)
// main {
//   font-size: rem-calc(18);
//   @include breakpoint(medium) {
//     font-size: rem-calc(21);
//   }
//   @include breakpoint(large) {
//     font-size: rem-calc(24);
//   }
// }

@mixin font-size-pt($mobilept, $desktoppt) {
  $mobilerem: math.div($mobilept, 16);
  $desktoprem: math.div($desktoppt, 16);
  @include font-size($mobilerem, $desktoprem);
}

@mixin typo-fs68() {
  //@include font-size(2, math.div(68, 16));
  @include font-size-pt(46, 68);
  letter-spacing: 0.01em;
  line-height: 1.03;
}
@mixin typo-fs34() {
  @include font-size-pt(22, 34);
  letter-spacing: 0.015em;
}
@mixin typo-fs28() {
  @include font-size-pt(13, 28);
  line-height: 1.143;
  letter-spacing: 0.02em;
}

body {
  //Dynamic Font Size: mobile, desktop
  @include font-size-pt(16, 22);

  font-family: 'Franca', sans-serif;
  font-weight: 500;

  //color:#D9D7D7;
  color:$blacktext;

  line-height: 1.375;

  @include breakpoint (small down) {
    font-weight: 400;
  }
}

strong {
  font-weight: 700;
}

h1 {
  font-weight: 600;
  @include typo-fs68();
  margin:0 0 0.5em 0;
  color:$redtext;
}
h2 {
  margin:0 0 0.5em 0;
  @include typo-fs28();
}
h3 {
  margin:0 0 0.5em 0;
}
p {
  margin:0 0 1em 0;
}

a {
  text-decoration: none;
  color:inherit;
}

a:focus {
  outline: none;
}

/*
.bl-richtext {
  &.geschaeft {
    h2 {
      margin-top:1.25em;
      margin-bottom:0.25em;
      text-transform: uppercase;
    }
  }
}
*/

@mixin typo-redtext() {
  color:$redtext;
}
@mixin typo-bigclaim() {
  color:$whitetext;
  @include font-size-pt(46, 146);

  letter-spacing: 0.01em;
  line-height: 1.0;
  font-weight:600;
}
@mixin typo-bigclaimsubtitle() {
  color:$whitetext;
  @include font-size-pt(25, 68);

  letter-spacing: 0.01em;
  line-height: 1.03;
  font-weight:400;
}
