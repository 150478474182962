.bl-projoverview {
    .items {
        display: grid;
        gap:var(--gap1);
        grid-template-columns: repeat(3, 1fr);
    }
    .item {
        .ovimg {
            display:block;
            width: 100%;
        }
        h2 {
            @include typo-redtext();
            @include typo-fs28();
            font-weight: 600;
            letter-spacing: 0.025em;
            margin-top:0.25em;
            margin-bottom: 0;
        }
    }
}

@include breakpoint(medium down) {
    .bl-projoverview {
        .items {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@include breakpoint(small down) {
    .bl-projoverview {
        .items {
            gap: rem-calc(25 18);
        }
        .item {
            h2 {
                font-size:rem-calc(16);
                line-height: 1.143;
            }
        }
    }
}