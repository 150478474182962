.bl-claim {
    background-color: $redbg;
    height:15em;
}

.fixclaim {
    opacity:0;
    pointer-events: none;

    position:fixed;
    left:0;
    right:0;
    top:50%;
    transform: translateY(-50%);

    @include typo-bigclaim();

    z-index: 10;

    .subtitle {
        @include typo-bigclaimsubtitle();
        margin-top:0.35em;
    }

    .inner {
        //for translateY animation
        transition: transform 0.05s;
    }
}