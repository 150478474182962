.bl-prozessvisual {
    display:grid;
    grid-template-columns:1fr 20% 1fr;
    grid-template-rows: auto;
    grid-template-areas:
        "text2 illu text1"
        "text4 illu text3"
        "text6 illu text5";
    gap:rem-calc(100 10); //Y X

    @include breakpoint (medium down) {
        grid-template-columns:20% 1fr;
        grid-template-rows: auto;
        grid-template-areas:
            "illu text1"
            "illu text2"
            "illu text3"
            "illu text4"
            "illu text5"
            "illu text6";
        gap:rem-calc(50 30); //Y X
    }

    @include breakpoint (large) {
        transform:skewY(-26.565deg);
        .text,
        .illu {
            transform:skewY(26.565deg);
        }
    }

    .text {
        h1 {
            color:$blacktext;
            margin:0;
        }
        h2 {
            color:$whitetext;
            margin-bottom: 0.5em;
        }
        h1, h2 {
            @include typo-fs68();
            @include font-size-pt(30, 68); //mobile smaller
            @media (min-width:700px) and (max-width:1200px) {
                font-size:rem-calc(44);
            }
            font-weight:600;
        }
    }
    .text1 { grid-area:text1; }
    .text2 { grid-area:text2; }
    .text3 { grid-area:text3; }
    .text4 { grid-area:text4; }
    .text5 { grid-area:text5; }
    .text6 { grid-area:text6; }

    .illu {
        grid-area:illu;
        background-image:url('/assets/app/images/prozessillu.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

@include breakpoint (small down) {
    .bl-prozessvisual {
        .text {
            font-size:rem-calc(16);
            line-height: 1.375;
            font-weight: 400;
        }
    }
}